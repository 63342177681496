.nav-title{
  font-weight: bold;
}

.nav-link{
  line-height: 24px;
}

.nav-section{
  margin: 0 0 20px 0;
}

.navigation-link:hover{
  color: whitesmoke;
}