@import "./src/App.scss";

.top-bar{
    background-color: $color1;
    color: $color5;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.title{
    text-transform: uppercase;
}

.title img{
    width: 24px;
    margin: 0 5px;
}

@media (min-width: 768px) {
    .title img{
        display: none;
    }
}

@media (max-width: 767px) {
    .title a{
        display: none;
    }
}

.layout{
    min-height: 100vh;
    display: flex;
}

.donate-button:hover{
    color: whitesmoke;
}

.main-section{
    padding: 0 20px;
}