@import "./src/App.scss";

.navigation{
    background-color: $color1;
    color: whitesmoke;
    width: 250px;
    min-width: 250px;
    padding: 10px;
}

a:hover{
    color: whitesmoke;
}

.hide-navigation{
    display: none;
}