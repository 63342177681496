$color1: #03312E;
$color2: #037171;
$color3: #00B9AE;
$color4: #02C3BD;
$color5: #009F93;

a{
  text-decoration: none;
  color: $color3;
}
